<template>
  <div class="message-template">
    MessageTemplate
  </div>
</template>

<script>
export default {
  name: 'MessageTemplate'
}
</script>

<style scoped>

</style>
